import "$frontend/utils/polyfill";
import { Shell } from "$frontend/shell";
import { hydrate } from "bun-react-ssr/hydrate";
hydrate(Shell, {
    normalizeUrl (url) {
        const parsed = new URL(url, location.href);
        parsed.searchParams.delete("workaround");
        return parsed.pathname + parsed.search;
    }
}).catch(console.error);
